export const ActionTypes = {
    FETCH_ROLE_PERMISSION : "FETCH_ROLE_PERMISSION",
    FETCH_PERMISSION : "FETCH_PERMISSION",
    FETCH_CATEGORIES : "FETCH_CATEGORIES",
    FETCH_STAFFS : "FETCH_STAFFS",
    SLIDER_PERMISSION_DENINE : "PERMISSION_DENINE",
    PRODUCT_PERMISSION_DENINE : "PRODUCT_PERMISSION_DENINE",
    STORE_PERMISSION_DENINE : "STORE_PERMISSION_DENINE",
    BANNER_PERMISSION_DENINE : "BANNER_PERMISSION_DENINE",
    CATEGORY_PERMISSION_DENINE : "CATEGORY_PERMISSION_DENINE",
    
    FETCH_SUB_CATEGORIES : "FETCH_SUB_CATEGORIES",
    FETCH_PERCENT : "FETCH_PERCENT",
    UPDATE_PERCENT : "UPDATE_PERCENT",
    FETCH_SLIDERS : "FETCH_SLIDERS",
    ADD_SLIDER : "ADD_SLIDER",
    ADD_USER: "ADD_USER",
    USER_LOADING: "USER_LOADING",
    USER_LOADED: "USER_LOADED",
    USER_ERROR : "USER_ERROR",
    USER_ERROR_MESSAGE_CLEAN_UP : "USER_ERROR_MESSAGE_CLEAN_UP",
    LOGIN_SUCCESS : "LOGIN_SUCCESS",
    FETCH_STORES : "FETCH_STORES",
    FETCH_SINGLE_STORE : "FETCH_SINGLE_STORE",
    ERROR_MESSAGE : "ERROR_MESSAGE",
    SUCCESS_MESSAGE : "SUCCESS_MESSAGE",
    CATEGORY_CLEAN_UP : "CATEGORY_CLEAN_UP",
    SLIDER_CLEAN_UP : "SLIDER_CLEAN_UP",
    STORE_CLEAN_UP : "STORE_CLEAN_UP",
    STORE_MESSAGE : "STORE_MESSAGE",
    FETCH_BANNERS : "FETCH_BANNERS",
    ADD_BANNER : "ADD_BANNER",
    DELETE_BANNER : "DELETE_BANNER",
    BANNER_ERROR_MESSAGE : "BANNER_ERROR_MESSAGE",
    BANNER_CLEAN_UP : "BANNER_CLEAN_UP",
    FETCH_PRODUCTS : "FETCH_PRODUCTS",
    FETCH_SINGLE_PRODUCT : "FETCH_SINGLE_PRODUCT",
    PRODUCT_MESSAGE : "PRODUCT_MESSAGE",
    PRODUCT_CLEAN_UP : "PRODUCT_CLEAN_UP",
    ABOUT_US_POST : "ABOUT_US_POST",
    GET_ABOUT_US : "GET_ABOUT_US",
    GET_ABOUT_US_ERROR : "GET_ABOUT_US_ERROR",
    ABOUT_US_CLEAN_UP : "ABOUT_US_CLEAN_UP",
    SINGLE_PRODUCT_CLEAN_UP : "SINGLE_PRODUCT_CLEAN_UP",
    
}