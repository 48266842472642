import { setCookie,getCookie } from "../../cookies/cookie";
import { ActionTypes } from "../actions/types";


const staffInitialState = {    

    error : false,
    errorMessage : [],
    isLoading: true,
    staff: [],
    deninePermission : false,
    deninePermissionMessage : [],
    deninePermission : false,
    deninePermissionMessage : []
    
}

export const StaffReducer = (state = staffInitialState, {type, payload}={}) => {

    switch (type){
        case ActionTypes.FETCH_STAFFS:
            return{
                ...state,
                isLoading: false,
                staff : payload
            }
        default:
            return state;
    }
};